import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  partnerLoginServ,
  removeImageServ,
  updateUserProfileServ,
  updateUserStatusServ,
  uploadImageServ,
} from './userPartnerApi';
import { setNotification } from '../notification/notificationSlice';

const initialState = {
  loading: false,
  error: null,
  userData: null,
};

export const changePartnerAccessibilityType = createAsyncThunk(
  'change_partner_accessibility_type',
  async ({partnerId, status, userId}, {dispatch})=> {
    const res = await updateUserStatusServ(partnerId, status, userId);
    if(res.success){
      dispatch(updateUserData({status}));
      return res;
    }
    throw new Error('changePartnerAccessibilityType error', res.message);
});

export const partnerLogin = createAsyncThunk(
  'partner_login',
  async ({ partnerId, userId })=> {
    const res = await partnerLoginServ(partnerId, userId);
    if(res.success){
      return res;
    }
    throw new Error('partnerLogin error', res.message);
});

export const uploadProfileImage = createAsyncThunk(
  'upload_profile_image',
  async ({partnerId, profileImage})=> {
    let formData = new FormData();
    formData.append('profileImage', profileImage);
    const res = await uploadImageServ(partnerId, formData);
    if (res.success) return res;
    throw new Error('uploadProfileImage Error', res.message);
  }
)

export const uploadDashboardImage = createAsyncThunk(
  'upload_dashboard_image',
  async ({partnerId, dashboardImage})=> {
    let formData = new FormData(); 
    formData.append('dashboardImage',  dashboardImage);
    const res = await uploadImageServ(partnerId, formData);
    if (res.success) return res;
    throw new Error('uploadDashboardImage Error', res.message);
  }
);

export const deleteProfileImage = createAsyncThunk(
  'delete_profile_image',
  async (data)=> {
    const res = await removeImageServ(data);
    if (res.success) return null;
    throw new Error('deleteProfileImage Error', res.message);
  }
)

export const deleteDashboardImage = createAsyncThunk(
  'delete_dashboard_image',
  async (data)=> {
    const res = await removeImageServ(data);
    if(res.success) return null;
    throw new Error('deleteDashboardImage Error', res.message);
  }
);

export const updateUserProfile = createAsyncThunk(
  'edit_user_profile',
  async (data, {dispatch})=> {
    const res = await updateUserProfileServ(data);
    if(res.success){
      dispatch(setNotification({type: res.message, message: 'success'}));
      return { data };
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('Edit user profile failed', res.message);
  }
);


export const userPartnerSlice = createSlice({
  name: 'user_partner',
  initialState,
  reducers: {
    getPartnerMeSuccess: (state, action)=> {
      state.loading = false;
      state.userData = { ...state.userData, ...action.payload };
    },
    updateUserData: (state, action)=> {
      state.userData = { ...state.userData, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadProfileImage.pending, (state) => {
        state.loading = true;
        state.image = null;
      })
      .addCase(uploadProfileImage.fulfilled, (state, action) => {
        state.loading = false;
        state.userData.profileImage = action.payload.data;
      })
      .addCase(uploadProfileImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.error("Error redux async func 'uploadProfileImage'", action.error);
      })
      .addCase(deleteProfileImage.pending, (state) => {
        state.loading = true;
        state.image = null;
      })
      .addCase(deleteProfileImage.fulfilled, (state, action) => {
        state.loading = false;
        state.userData.profileImage = action.payload;
      })
      .addCase(deleteProfileImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.error("Error redux async func 'deleteProfileImage'", action.error)
      })
      .addCase(uploadDashboardImage.pending, (state) => {
        state.loading = true;
        state.image = null;
      })
      .addCase(uploadDashboardImage.fulfilled, (state, action) => {
        state.loading = false;
        state.userData.dashboardImage = action.payload.data;
      })
      .addCase(uploadDashboardImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.error("Error redux async func 'uploadDashboardImage'", action.error)
      })
      .addCase(deleteDashboardImage.pending, (state) => {
        state.loading = true;
        state.image = null;
      })
      .addCase(deleteDashboardImage.fulfilled, (state, action) => {
        state.loading = false;
        state.userData.dashboardImage = action.payload;
      })
      .addCase(deleteDashboardImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.error("Error redux async func 'deleteDashboardImage'", action.error)
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = {...state.userData, ...action.payload.data};
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.error("Error redux async func 'updateUserProfile'", action.error);
      })
      .addCase(partnerLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(partnerLogin.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(partnerLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.error("Error redux async func 'Partner login'", action.error);
        window.location.href="/login";
      })
  },
});

export const {
  getPartnerMeSuccess,
  updateUserData
} = userPartnerSlice.actions;
export const selecUser = (state)=> state.userPartner;
export default userPartnerSlice.reducer;