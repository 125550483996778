import { memo } from 'react';
import { Button, ButtonGroup } from '@material-ui/core'


export const ChatMenu = memo(({setCurrentTab, currentTab, pastChatsLength})=> {  
  return(
    <ButtonGroup size="small" aria-label="small outlined button group">
      <Button
        onClick={() => {
          setCurrentTab(0)
        }}
        className={{disabled: currentTab === 0}}
      >
        Current Chat
      </Button>
      <Button
        disabled={!pastChatsLength}
        onClick={() => {
          setCurrentTab(1);
        }}
        className={{disabled: currentTab === 1}}
      >
        Past Chats ({pastChatsLength})
      </Button>
    </ButtonGroup>
  )
}, (prevProps, nextProps)=> {
  return(
    prevProps.setCurrentTab === nextProps.setCurrentTab &&
    prevProps.currentTab === nextProps.currentTab &&
    prevProps.pastChatsLength === nextProps.pastChatsLength
  )
})