import styled from 'styled-components/macro'


export const PartnerFormContainer = styled.div`
  max-width: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--seventh-paddind);
  width: 400px;
`

export const PartnerFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 20px;
  justify-content: flex-end;
`