import * as S from "./ChatDataList.styles";
import cx from "classnames";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { IMAGE_TYPES, VIDEO_TYPES } from "configs/constants";
import { scrollMessages } from "socket/socket";
import { SeenMessageIcons } from 'components';
import { addHistory } from "redux_toolkit/reducers/chat/chatSlice";


export const ChatDataList = memo(({
  messagesChat=[],
  chat,
  agentName
}) => {
  console.log("messagesChat =--------> ", messagesChat);
  
  const dispatch = useDispatch();
  const { _id: userPartnerId, messageFlag } = useSelector(({ userPartner }) => userPartner.userData);
  const lastItemMessages = messagesChat.length? messagesChat[messagesChat.length-1][0] : 0;

  const [length, setLength] = useState(messagesChat?.length);
  const oldScrollRef = useRef(0);
  const lastMsgIndexRef = useRef(lastItemMessages);
  const areaRef = useRef(null);

  useEffect(() => {
    if (messageFlag && document.hidden && messagesChat.length)
      new Notification('New Message', {body: 'You have a new message!'});
  }, [messagesChat?.length, messageFlag]);

  useEffect(() => {
    switch (true) {
      case messagesChat?.length === length:
        setLength(messagesChat?.length);
        areaRef.current.scrollTo(0, areaRef.current.scrollHeight);
        break;
      case lastMsgIndexRef.current !== lastItemMessages:
        areaRef.current.scrollTo(0, areaRef.current.scrollHeight);
        lastMsgIndexRef.current = lastItemMessages;
        break;
      default:
        areaRef.current.scrollTop = areaRef.current.scrollHeight - areaRef.current.clientHeight - oldScrollRef.current;
        break;
    }
  }, [messagesChat?.length, length, lastItemMessages]);

  const isUserMessage = useCallback((senderId) => {
    return chat.companion._id !== senderId;
  }, [chat?.companion._id]);

  const handleScroll = (e) => {
    if (e.currentTarget.scrollTop === 0) {
      oldScrollRef.current = areaRef.current.scrollHeight - areaRef.current.clientHeight;
      scrollMessages(
        chat._id,
        messagesChat[0],
        (data) => {
          dispatch(addHistory({chatId: chat._id, messages: data}));
        }
      )
    }
  };

  const checkFileType = (filePath)=> {
    const fileSplit = filePath.split('.');
    const fileType = fileSplit[fileSplit.length-1];

    let type = '';
    if(IMAGE_TYPES.includes(fileType)) type = 'image';
    if(VIDEO_TYPES.includes(fileType)) type = 'video';
    return type;
  }

console.log("messagesChat, chat =ChatDataList-=-=--> ", messagesChat, chat);
const unreadMessages = [];

  return (
    <S.ChatHistoryContainer
      className="ChatDataList"
      ref={areaRef}
      onScroll={handleScroll}
    >
      {messagesChat?.map((message, index) => {
        const thisUserId = message[1];
        const text = message[2];
        const file = message[3];
        const date = message[4];
        const isBot = !thisUserId;
        const createDate = (new Date(date)).getTime();
        const yourMessage = userPartnerId === thisUserId;        
        // console.log("thisUserId, text, file, date, isBot, createDate, yourMessage",thisUserId, text, file, date, isBot, createDate, yourMessage);
        
        return (
          <S.MessageItem
            key={index * Math.random()}
            className={cx({
              myMessage: isUserMessage(thisUserId),
              file: !!file,
              botMessage: !thisUserId 
            })}
          >
            {
              !isBot? <>
                <S.MessageInfo>
                  {
                      <S.SenderName>
                        {yourMessage? 'you' : (chat.logUsers[thisUserId] || agentName)}
                      </S.SenderName>
                  }
                  { moment(createDate).local().format('DD-MM-YYYY HH:mm') }
                </S.MessageInfo>
                <div className='message-content'>{text}</div>
                {
                  file.length ?
                  <a className='file-content' href={file} target="_blank" rel="noreferrer">
                    {checkFileType(file) === 'image' && <S.FileImage alt={file} src={file} />}
                    {checkFileType(file) === 'video' && <video autoPlay controls src={file} />}
                  </a>:''
                }
                {yourMessage && <SeenMessageIcons seen={true} />}
              </>:<S.BotContainer>
                <div className='message-content'>
                  {chat.logUsers[text.split('_')[0]]} { moment(createDate).local().format('DD-MM-YYYY HH:mm') }
                </div>
                <span>{text.split('_')[1]}</span>
              </S.BotContainer>
            }
          </S.MessageItem>
        );
      })}
    </S.ChatHistoryContainer>
  );
},(prevProps, nextProps)=> {
    return(
      prevProps.messagesChat.length === nextProps.messagesChat.length &&
      prevProps.chat === nextProps.chat &&
      prevProps.agentName === nextProps.agentName
    );
  }
);
