import * as S from "./HistoryDetails.styles";
import cx from "classnames";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ReactToPdf from "react-to-pdf";
import DownloadIcon from "@mui/icons-material/GetApp";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "ui";
import { Transcript, UserInfo, ChatLogs } from "components";
import { getVisitorChats } from "redux_toolkit/reducers/partner/visitors/visitorsSlice";


export const HistoryDetails = memo(({
  partnerId,
  item,
  onClose,
  visitorPartner,
  showActions = true
}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const ref = useRef();

  const getAllchatsStory = useCallback(()=> {
        dispatch(getVisitorChats({partnerId, visitorId: item.visitorPartner[0]._id}));
  }, [item, partnerId, dispatch]);

  useEffect(()=> {
    if (activeTab === 2 && item.visitorPartner) getAllchatsStory();
  }, [activeTab, getAllchatsStory, item.visitorPartner])

  const transcript = {
    chatHistory: item.messages,
    rating: item.rating
  };

  const userInfo = {
    startDate: item.createdAt,
    endDate: item.updatedAt,
    chatHistory: [],
  };

  if(Array.isArray(item.visitorPartner)) {
    userInfo.visitorId = item.visitorPartner[0]?.userId;
    userInfo.recipentId = item.visitorPartner[0]?.userId;
    userInfo.referrers = item.visitorPartner[0]?.user.referrers;
  }

  const options = {
    unit: "px",
    format: [247, 500]
  };

  return (
    <S.HistoryDetailsContainer>
      <S.DetailsHeader>
        {showActions && 
          <S.TabsContainer>
            <Button
              className={cx({ disabled: activeTab === 1 })}
              onClick={() => setActiveTab(1)}
            >
              Transcript
            </Button>
            <Button
              className={cx({ disabled: activeTab === 2 })}
              onClick={() => setActiveTab(2)}
            >
              User Info
            </Button>
            <Button
              className={cx({ disabled: activeTab === 3 })}
              onClick={() => setActiveTab(3)}
            >
              Chat Logs
            </Button>
          </S.TabsContainer>
        }
        <S.ActionsContainer>
          {activeTab === 1 &&  (
            <Button color="secondary">
              {ref && (
                <ReactToPdf targetRef={ref} filename="transcript" options={options} addPage={{ format: [247, 1000], orientation: 'p' }}>
                  {(asd) => {
                    return (
                      <DownloadIcon onClick={asd.toPdf} />
                    )
                  }}
                </ReactToPdf>
              )}
            </Button>
          )}
          <Button color="secondary" onClick={onClose}>
            <CloseIcon />
          </Button>
        </S.ActionsContainer>
      </S.DetailsHeader>
      <S.DetailsContent>
        {activeTab === 1 ? (
          <Transcript transcript={transcript} ref={ref} visitorPartner={visitorPartner} />
        ) : activeTab === 2 ? (
          userInfo.visitorId && <UserInfo chatInfo={userInfo} canEdit />
        ) : (
          <ChatLogs item={item} />
        )}
      </S.DetailsContent>
    </S.HistoryDetailsContainer>
  );
}, (prevProps, nextProps)=> {
  return (
    prevProps.partnerId === nextProps.partnerId &&
    prevProps.item === nextProps.item &&
    prevProps.onClose === nextProps.onClose &&
    prevProps.visitorPartner === nextProps.visitorPartner &&
    prevProps.showActions === nextProps.showActions
  )
});