import styled from 'styled-components/macro';
import List from '@material-ui/core/List';

export const Title = styled.div`
  color: var(--primary-dark-blue);
  font-size: 28px;
  font-weight: var(--wider-font-weight);
  margin: 25px 0px;
`;

export const PartnersList = styled(List)`
  width: 100%;
  margin-bottom: 40px;
  max-height:250px;

  .MuiListItem-gutters{
    padding-left: 0 !important;
    font-size: var(--second-font-size);
  }

   div{
    padding-top:0px;
    padding-bottom:0px;
  }
`;

export const Email = styled.div`
  color: var(--primary-dark-blue);
  margin-top: 35px;
`;

export const NoPartner = styled.div`
  color: var(--primary-dark-blue);
  margin-bottom: 50px;
  font-size: 20px;
`;