import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    --primary-dark-blue: #0f3d56;
    --primary-gray: #eee;
    --first-font-size: 11px;
    --second-font-size: 12px;
    --third-font-size: 14px;
    --fourth-font-size: 16px;
    --fifth-font-size: 18px;
    --sixth-font-size: 20px;
    --seventh-font-size: 26px;
    --eighth-font-size: 30px;
    --ninth-font-size: 60px;
    --nerrow-font-weight: 300;
    --normal-font-weight: 400;
    --medium-font-weight: 500;
    --wide-font-weight: 600;
    --wider-font-weight: 700;
    --padding-primary: 10px;
    --padding-secondary: 8px;
    --third-paddind: 20px;
    --fourth-paddind: 20px 15px;
    --fifth-paddind: 10px 20px;
    --sixth-paddind: 10px 0px;
    --seventh-paddind: 4px 24px 20px;
  }
}`;

//example:  background-color: var(--seventh-paddind);