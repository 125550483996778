import styled from "styled-components/macro";

export const ChatContainer = styled.div`
  width: 100%;
  padding: var(--padding-primary);
`;

export const ChatLogsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #9e9e9e;
  font-weight: var(--wide-font-weight);
  font-size: var(--third-font-size);
`;

export const ChatLeftItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  min-height: 75px;
`;
