import styled from 'styled-components'


export const AssignAdminsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`

export const AdminCard = styled.div`
  width: 250px;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items:center;
  font-size: var(--fifth-font-size);
  padding: var(--padding-primary);
  background-color: ${({ isAssigned }) => isAssigned ? 'rgba(15, 61, 86, 0.3)' : '#fff'}; ;
  border: ${({ isAssigned }) => !isAssigned ? '1px solid #fff' : '1px solid #ccc'};
  border-radius: 5px;
  .MuiSvgIcon-root{
    cursor: pointer;
  }
  &:hover {
    border: 1px solid rgb(15, 61, 86) !important;
  }
`

export const AdminNameWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  span{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; 
    margin-left: 5px;
  }
`

export const AdminCardImg = styled.img`
  width: 35px;
  height: 35px;
`
