import styled from 'styled-components/macro'


export const AgentFormContainer = styled.div`
  padding: var(--seventh-paddind);
  display: flex;
  width: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const AgentFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`
export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  input{
    padding: 16px;
  }
`
export const SelectContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 15px;
  align-content: center ;
  input{
    padding: 16px;
  }
  #demo-simple-select-helper{
    padding-top: 7px;
  }
`
export const RoleSelect = styled.div`
  margin-top: 1px;
  min-width: ${({ isEdit }) => isEdit ? '450px' : '124px'};
  .MuiInput-root{
    width: 100%;
  }
  label{
    font-size: var(--second-font-size);
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 20px;
  justify-content: center;

`



