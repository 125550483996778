import styled from "styled-components/macro";
import MaterialBox from "@material-ui/core/Box";

export const PartnerSettingsContainer = styled.div`
  max-width: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--seventh-paddind);
  width: 700px;
  position: relative;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  & > button {
    color: black
  }
  & > :hover{
    background: #e3e3e34f;
  }
`;

export const BoxContainer = styled(MaterialBox)`
  display: flex;
  width: 100%;
  & > h4 {
    font-size: 20px;
  }
  & .radioButtonBox {
    display: flex;
    flex-direction: row;
  }
`;

export const SelectContainer = styled.div`
  min-width: 100px;
  margin-top: 2px;
  margin-left: 60px;
`;
