import styled from "styled-components/macro";
import MaterialCollapse from "@material-ui/core/Collapse";
import MaterialListItem from "@material-ui/core/ListItem";
import MaterialListItemText from "@material-ui/core/ListItemText";
import MaterialListItemIcon from "@material-ui/core/ListItemIcon";
import MaterialPopover from "@material-ui/core/Popover";

export const ListItem = styled(MaterialListItem)`
  color: #fff;
  transition: background 300ms linear;
  border-radius: 3px;
  padding: 0px 0px 3px 16px;
  height: 34px;

  &.opened{
    padding: 4px 10px 4px 16px;
  }
  &.popover {
    padding:5px 0;
    color: #555;
    &:hover {
      background: var(--primary-dark-blue);
      color: #fff;
    }
  }
  &.MuiListItem-gutters{
    @media(min-width: 950px){
      &:hover {
        background: var(--primary-dark-blue);
        color: #fff;
      }
    }
  }
 
  &.sidebarClosed {
    padding: 0 0 3px 24px;
  }
   &.Mui-selected {
     color: #fff;
     background: var(--primary-dark-blue);

    &:hover {
      color: #fff;
      background: var(--primary-dark-blue);
    }
  }
  @media (max-width: 950px){
    &.sidebarClosed{
      padding: 4px 10px 4px 28px !important;
    }
  }
  @media (min-width:1500px){
    &.opened{
      padding: 0px 0px 4px 16px;
      gap: 15px;
    }
  }
  @media (min-width:951px) and (max-width:1399px){
    &.sidebarClosed{
      padding:0px 0px 3px 18px;
    }
  }
`;

export const ListItemIcon = styled(MaterialListItemIcon)`
  min-width: 0px;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 0;
  &:not(.listItemIcon){
    margin-left: auto;
  }
  @media(max-width: 1399px){
    margin-left: auto;
  }

  svg {
    width: 18px !important;
    height: 18px !important;
  }
  @media (min-width: 1400px) {
    svg {
      width: 24px !important;
      height: 24px !important;
    }
    &.sidebarClosed {
      :nth-child(2n) {
        position: relative;
        left: 5px;
      }
    }
  }
`;

export const ListItemText = styled(MaterialListItemText)`
  &.SubMenuItem {
    text-align: start;
    margin-left:50px;
    color:#fff;
  }
  @media (min-width:1920px){
    &.SubMenuItem {
      margin-left: 55px;
    }
  }
  .MuiTypography-root {
    font-weight: var(--nerrow-font-weight);
    font-size: var(--third-font-size);
  }
  @media (min-width: 1920px){
    .MuiTypography-root {
      font-size: var(--third-font-size)!important;
    }
  }
  &.sidebarClosed {
    display: block;
  }
  
    &.subMenu span{
      margin-left:16px;
    
  }
`;

export const SubMenuContainerItems = styled(MaterialCollapse)`
  display: flex;
  color: #fff;
  width: 100%;
  flex-direction: column;
`;

export const Collapse = styled(MaterialCollapse)`
  color: #fff;
  margin-top: 5px;
`;

export const SubMenuListPopover = styled(MaterialPopover)`
   width:12%;
   top:370px;
  .MuiPaper-root {
    padding: var(--padding-primary) !important;
    &:before {
      right: 100%;
      bottom: 0;
      content: "";
      position: absolute;
      border-top: 12px solid transparent;
      border-right: 12px solid red;
    }
  }
  
  .MuiPopover-paper{
    padding:unset!important;
    width:80px;
    @media (max-width:950px){
      max-width:unset;
      padding:unset;
    }
  }
  a{
    color:black;
    padding:0px;
    margin-left:unset;
    text-align:center;
  }
  a:hover{
    &:hover {
      color: #fff;
    }
  }
`;

export const SubMenuList = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  &.list_popover a{
    padding:9px 0px 0px 0%;
  }
`;
