import styled from 'styled-components/macro'
import ListItem from '@material-ui/core/ListItem'

export const PartnerItemContainer = styled(ListItem)`
  display: flex;
`

export const PartnerName = styled.div`
    text-transform: capitalize;
    font-size: 25px;
    color: var(--primary-dark-blue);
    margin-left: 10px;
    max-width:100%;
    overflow:hidden;
    text-overflow: ellipsis;
  }
`
