import styled from 'styled-components/macro';
import MaterialTypography from '@material-ui/core/Typography';
import MaterialCollapse from "@material-ui/core/Collapse";

export const ChatsContent = styled.div`
  text-transform: none;
  color:unset;
`;

export const AgentName = styled.div`
  text-transform: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  cursor: pointer;
  color:unset;
  font-size: var(--fifth-font-size);
  font-weight: var(--wider-font-weight);
  color: var(--primary-dark-blue);
  .isOpened {
    transform: rotateX(180deg);
  }
`;

export const ChatsContainer = styled(MaterialCollapse)`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 100px;
  overflow: auto;
  &.MuiPaper-root{
    left:6%;
  }
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 3px 8px;
  cursor: pointer;
  margin: 5px 0;
`;

export const ListItemText = styled.div`
  text-transform: none;
  font-weight: var(--wider-font-weight);
`;

export const EmptyChatsListText = styled(MaterialTypography)`
  color: var(--primary-dark-blue);
  font-size: var(--fifth-font-size);
`;