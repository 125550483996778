import styled from 'styled-components/macro'
import MaterialDialog from '@material-ui/core/Dialog'

export const Dialog = styled(MaterialDialog)`
  .MuiTypography-root {
    color: #555;
    @media (max-width:950px){
      font-size:var(--second-font-size);
    }
  }
`

export const ModalContainer = styled.div`
`