import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPartnerServ } from './currentPartnerApi';
import { setNotification } from '../notification/notificationSlice';

const initialState = {
  currentPartner: null,
};

export const getCurrentPartner = createAsyncThunk(
  'get_current_partner',
  async (partnerId, {dispatch}) => {
    const res = await getPartnerServ(partnerId);
    if (res.success) {
      dispatch(setCurrentPartner(res.data));
      return res.data;
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
  }
);

export const currentPartnerSlice = createSlice({
  name: 'current_partner',
  initialState,
  reducers: {
    addTransferRequest: (state, action)=> {
      if(state.currentPartner && state.currentPartner.transferRequests){
        const data = state.currentPartner.transferRequests.find((item)=> item.chatId === action.payload.chatId);
        if (data) return;
      }

      state.currentPartner = { ...state.currentPartner, transferRequests: ([...(state.currentPartner?.transferRequests || []), action.payload]) };
    },
    removeTransferRequest: (state, action) => {
      state.currentPartner = {
        ...state.currentPartner,
        transferRequests: state.currentPartner.transferRequests?.filter(request => {
          return request.chatId !== action.payload.chatId
        }
      )};
    },
    setCurrentPartner: (state, action)=> {
      state.currentPartner = {...state.currentPartner, ...action.payload};
    },
    changePartnerRequest: (state, action)=> {
      state.currentPartner.request = +action.payload;
    }
  },
});

export const {
  addTransferRequest,
  removeTransferRequest,
  setCurrentPartner,
  changePartnerRequest
} = currentPartnerSlice.actions;
export const selectCurrentPartner = (state) => state.currentPartner;
export default currentPartnerSlice.reducer;