import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { Form as FormikForm } from 'formik'


export const FormTitle = styled.div`
  color: var(--primary-dark-blue);
  font-size: 28px;
  font-weight: var(--wider-font-weight);
  margin-bottom: 25px;
`

export const Form = styled(FormikForm)`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 310px;

  input {
    width: 100%
  }
`

export const FormError = styled.div`
  color: #b20000;
  font-size: 13px;
  font-weight: var(--wider-font-weight);
`

export const ForgotPassword = styled(Link)`
  color: var(--primary-dark-blue);
  font-size: 13px;
  font-weight: var(--wider-font-weight);
`