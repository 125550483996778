import styled from 'styled-components'
import MaterialButton from '@material-ui/core/Button'

export const AgentsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (min-width:951px){
    .chat-routing{
      margin-right: 50px;
   }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

export const ActionButton = styled(MaterialButton)`
  min-width: 0px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  svg.logout-icon {
    transform: rotate(180deg);
    width: 20px;
    height: 20px;
  }
`;