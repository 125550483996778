import cn from 'classnames';
import * as S from "./Transcript.styles";
import React, { useCallback, forwardRef } from "react";
import { useSelector } from 'react-redux';
import moment from "moment";
import { getMessageUserName } from 'pages/HistoryPage/HistoryPage.config';


const Transcript = forwardRef(({ transcript, visitorPartner }, ref) => {
  const teamList = useSelector(({ agents })=> [...agents.partnerTeam.values()]);

  const senderName = useCallback((id) => {
    return getMessageUserName(id, teamList, visitorPartner);
  }, [teamList, visitorPartner]);

  return (
    <S.TranscriptContainer >
      <S.ChatInfoContainer>
        <S.InfoText>Rating: -- {transcript.rating || ''}</S.InfoText>
        <S.InfoText>
          Comment: --
        </S.InfoText>
      </S.ChatInfoContainer>
      <S.ChatMessagesContainer ref={ref}>
        {transcript &&
          transcript.chatHistory.map((message) => {
            const isBot = !message.userId;
            return (
              <S.MessageItem
                key={message._id}
                className={cn({ botMessage: isBot})}
              >
                <S.MessageUser className={cn({
                  agentMessage: senderName(message.userId).agentMessage && message.userId,
                  visitorMessage: !senderName(message.userId).agentMessage && message.userId,
                })}>
                  <S.MessageSender>
                    {isBot ? senderName(message.message.split('_')[0]).name : message.name}
                  </S.MessageSender>
                  {
                    message.message &&  !message.file ?
                      <S.Message className={cn({ botMessage: isBot})}>
                        {isBot ? message.message.split('_')[1] : message.message}
                      </S.Message>:
                      <S.MessageImage src={`${message.file}`} alt="messageImage"/>
                  }
                  <S.MessageDate>
                    {moment(new Date(message.date)).format('LLLL')}
                  </S.MessageDate>
                </S.MessageUser>
            
              </S.MessageItem>
            );
          })}
      </S.ChatMessagesContainer>
    </S.TranscriptContainer>
  );
});

export { Transcript };
