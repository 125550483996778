import styled from 'styled-components/macro'
import MaterialAccordionDetails from '@material-ui/core/AccordionDetails'


export const VisitorsCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--first-font-size);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: #FF1100;
  margin-left: auto;
  color: #fff;
  line-height: 1;

  &.empty {
    background: #800800;
  }
  @media (max-width:950px){
    width: 16px;
    height: 16px;
    font-size: 10px;
  }
`

export const AccordionDetails = styled(MaterialAccordionDetails)`
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiButtonBase-root {
    margin-top: 20px;
  }
`