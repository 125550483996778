import * as S from "./PartnerItem.styles";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import PartnerLoginIcon from "images/partnerLoginIcon.png";
import { StatusSelect } from "components";
import { STATUSES_LIST } from "configs/constants";
import {
  changePartnerAccessibilityType,
  updateUserData,
  partnerLogin
} from "redux_toolkit/reducers/user_partner/userPartnerSlice";


export const PartnerItem = memo(({
  partner,
  currentUser,
}) => {
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState(STATUSES_LIST[2].value);

  const openPartner = () => {
    dispatch(partnerLogin({ userId: currentUser._id, partnerId: partner._id })).then((r) => {
      const newWindow = window.open('', '_blank');
      localStorage.setItem(partner.key, r.payload.token);

      if (newWindow) {
        newWindow.location.href = `${partner.key}/home`;
        newWindow.opener = null;
      }

      dispatch(changePartnerAccessibilityType({ partnerId: partner._id, status: selectedStatus, userId: currentUser._id }));
    });
  };

  return (
    <S.PartnerItemContainer button disabled={!partner.status || !partner.userPartnerStatus}>
      <img alt="partner-login-logo" src={PartnerLoginIcon} />
      <StatusSelect partner={partner} setStatus={(status) => {
        setSelectedStatus(status);
        dispatch(updateUserData({ status }));
      }} />
      <S.PartnerName onClick={openPartner}>
        {partner.name}
      </S.PartnerName>
    </S.PartnerItemContainer>
  );
}, (prevProps, nextProps)=> {
  return (
    prevProps.partner === nextProps.partner &&
    prevProps.currentUser === nextProps.currentUser &&
    prevProps.updateUserData === nextProps.updateUserData &&
    prevProps.changePartnerAccessibilityType === nextProps.changePartnerAccessibilityType &&
    prevProps.partnerLogin === nextProps.partnerLogin
  )
});