import { IconButton } from "@material-ui/core";
import styled from "styled-components/macro";

export const ChatList = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: fixed;
  z-index: 10;
  bottom: 0px;
  right: 5px;
  display: flex;
  gap: 10px;
`;

export const ChatCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  width: 130px;
  height: 32px;
  border-radius: 8px 8px 0px 0px;
  background: rgb(255, 131, 8) none repeat scroll 0% 0%;
  &.readedAllMessages {
    background: linear-gradient(90deg, rgb(11, 0, 178) 0%, rgb(27, 27, 27) 100%);
  }
`;

export const ClientName = styled.div`
  color: #fff;
  font-size: 13px;
  cursor: pointer;
  width: 115px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

export const UnreadMessagesCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  color: #fff;
  height: 20px;
  border-radius: 50%;
  background: #3f51b5;
  margin-left: auto;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--first-font-size);
    font-weight: var(--wide-font-weight);
    margin-top: 1px;
  }
`;

export const CloseButton = styled(IconButton)`
  color: #fff;
  padding: 0;
`;
