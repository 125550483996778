import styled from "styled-components/macro";

export const ProfileFormContainer = styled.div`
  width: 100%;

  .MuiInputLabel-marginDense{
    background-color:unset!important;
  }
`;

export const ProfileFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
