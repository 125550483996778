import React from "react";

export function WidgetRect() {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="77 80 100 100" > 
        <g id="Group_71" data-name="Group 71" transform="translate(-391.5 -14.5)">
          <g transform="matrix(1, 0, 0, 1, 391.5, 14.5)" filter="url(#Rectangle_14)">
            <rect id="Rectangle_14-2" data-name="Rectangle 14" width="155" height="155" rx="32" transform="translate(49.5 49.5)" fillOpacity="0"/>
          </g>
          <g id="Group_66" data-name="Group 66" transform="translate(462.105 95.867)">
            <g id="Group_1" data-name="Group 1" transform="translate(12.935 12.936)">
              <path id="Path_1" data-name="Path 1" d="M588.264,698.081H563.113c-1.519,1.718-3.322,3.628-4.935,5.538-3.8,4.3-12.623,7.83-13.952,8.211s-4.745.191-2.657-2.2,4.176-8.593,4.176-11.458H539.53a10.006,10.006,0,0,1-10.005-10.007V656.055a9.864,9.864,0,0,1,9.91-10.006h48.793a10,10,0,0,1,10,10v32a10.056,10.056,0,0,1-9.966,10.026Zm-7.483-31.359a4.879,4.879,0,0,0-4.858,4.858,4.8,4.8,0,0,0,4.858,4.763,4.811,4.811,0,1,0,0-9.622ZM564.2,667.2a4.858,4.858,0,1,0,4.858,4.858A4.879,4.879,0,0,0,564.2,667.2Zm-16.672.571a4.858,4.858,0,1,0,4.858,4.858A4.818,4.818,0,0,0,547.533,667.77Zm58.351-5.048H604.46a5.06,5.06,0,0,1,.095,1.239V694.55a9.3,9.3,0,0,1-9.281,9.281H566.011c-1.894,2.083-5.114,5.493-5.114,5.493a4.537,4.537,0,0,0,4.261,2.557h25.53s3.62,5.714,5.335,7.143c1.905,1.429,5.24,3.43,8,3.62,2.762.095,3.049-.762,2.572-1.429a32.306,32.306,0,0,1-2.382-5.335,16.674,16.674,0,0,1-.286-4h1.924a9.363,9.363,0,0,0,9.336-9.336V672.059a9.337,9.337,0,0,0-9.307-9.336Z" transform="translate(-529.523 -646.049)" fill="#fcfcfc"/>
            </g>
          </g>
        </g>
      </svg>
    </>
  )
}

export function WidgetCircular() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="70 70 110 110">
      
      <g id="Group_70" data-name="Group 70" transform="translate(-679.975 -36.032)">
        <g id="Layer_2" data-name="Layer 2" transform="translate(729.475 85.532)">
          <rect id="Rectangle_5" data-name="Rectangle 5" width="78" height="84" transform="translate(13.782 26.468)" opacity="0.73" fill="url(#pattern)"/>
          <g id="Group_69" data-name="Group 69">
            <g id="Group_3" data-name="Group 3" transform="translate(65.194 52.397)">
              <path id="Path_2" data-name="Path 2" d="M930.023,786.765a29.933,29.933,0,0,0,8.779-21.072c0-17.056-14.694-30.933-32.755-30.933s-32.755,13.877-32.755,30.933h0c0,17.056,14.694,30.933,32.755,30.933a34.721,34.721,0,0,0,6.04-.528l9.845,3.693a7.539,7.539,0,0,0,8.249-2.011l.1-.11a7.51,7.51,0,0,0,1.043-8.307Zm-39.593-15.83a5.759,5.759,0,1,1,5.759-5.759A5.765,5.765,0,0,1,890.43,770.935Zm15.618,0a5.759,5.759,0,1,1,5.759-5.759A5.765,5.765,0,0,1,906.048,770.935Zm15.62,0a5.759,5.759,0,1,1,5.759-5.759A5.765,5.765,0,0,1,921.669,770.935Z" transform="translate(-873.292 -734.76)" fill="#fff"/>
            </g>
            <g id="Group_5" data-name="Group 5" transform="translate(23.149 35.972)">
              <path id="Path_3" data-name="Path 3" d="M477.877,570.4a33.311,33.311,0,0,0-27.007-13.465c-18.061,0-32.755,13.877-32.755,30.933a29.923,29.923,0,0,0,8.779,21.072l-1.3,2.6a7.508,7.508,0,0,0,1.044,8.307l.1.11a7.552,7.552,0,0,0,5.6,2.492,7.475,7.475,0,0,0,2.648-.481l9.845-3.693a34.468,34.468,0,0,0,12.355-.062,35.078,35.078,0,0,1-2.889-13.918h0C454.3,589.083,464.031,576,477.877,570.4ZM435.25,593.11a5.759,5.759,0,1,1,5.759-5.759A5.765,5.765,0,0,1,435.25,593.11Zm15.62,0a5.759,5.759,0,1,1,5.759-5.759A5.765,5.765,0,0,1,450.87,593.11Z" transform="translate(-418.115 -556.935)" fill="#fff"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export function SeenIcon({color, size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill="none"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 12l4 4 8-8" />
      <path d="M9 12l4 4 8-8" />
    </svg>
  );
}