const getPartnerName = ()=> {
    const localStorageKeys = Object.keys(localStorage);
    const keytoken = window.location.pathname.split('/')[1];
    return localStorageKeys.find(item=> item === keytoken);
}

export const getPartnerId = ()=> {
    const homePageTo = getPartnerName();
    const partnerToken = localStorage.getItem(homePageTo);
    return partnerToken?.split('|')[1];
}