import styled from 'styled-components/macro'
import MaterialButton from '@material-ui/core/Button'


export const ProfileTabContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const TabTitle = styled.h1`
  color: #3c4858;
  @media (max-width:950px){
    font-size: var(--sixth-font-size);
  }
`
export const DashboardTitle = styled.p`
  color: #3c4858;
  font-size:17px;
`
export const ProfileTabContent = styled.div`
  display: flex;
  align-items: center;
  width: 270px;
  gap: 10px;
  flex-direction: column;
`

export const FormContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: column;
  width: 100%;
  
`

export const TabTitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
`

export const ImgContainer = styled.div`
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: #e0e0e0;
  min-width: unset;
  cursor: pointer;

  &:hover {
    background: #d5d5d5;
  }
`
export const AddPictureIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;

  .MuiButton-label{
    gap: 10px;
  }

  button{
    min-width:80px !important;
    padding: 5px !important;
  }
`


export const UploadBtn=styled(MaterialButton)`
   min-width:60px;
   background-color: var(--primary-dark-blue);
   color:white;
   font-size:0.6em;
   box-shadow: 0 2px 2px 0 rgb(15 61 86 / 14%), 0 3px 1px -2px rgb(15 61 86 / 20%), 0 1px 5px 0 rgb(15 61 86 / 12%);
   &:hover {
    background: var(--primary-dark-blue)!important;
  }
`

export const Image=styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    left:0;
    top:0;

`

export const Delete=styled.span`
   position:absolute;
   right:0;
   color:red;
   display:inline-block;
   top:0;
   
   svg{
    background-color:red;
    color:white;
    font-size:1em!important;
    border-radius:50%;
   }
`