import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, StylesProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Pages } from '../routes/Routes';
import { GlobalStyles } from './App.style';

export function App() {
  return (
    <div className="App">
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <CssBaseline />
          <GlobalStyles />
          <BrowserRouter>
            <Pages />
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </div>
  );
}

export default App;