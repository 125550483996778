import * as S from './Loader.styles'
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';


export const Loader = ({ size, color, styles }) => {
  return (
    <S.LoaderContainer style={{ ...styles }}>
      <CircularProgress color={color} size={size} />
    </S.LoaderContainer>
  )
}