import { Appearance } from './Appearance/Appearance';
import { Forms } from './Forms/Forms';
import { GettingStarted } from './GettingStarted/GettingStarted';
import { Settings } from './Settings/Settings';

export const tabs = [
  {
    label: 'Getting started',
    value: 0,
    component: GettingStarted
  },
  {
    label: 'Appearance',
    value: 1,
    component: Appearance
  },
  {
    label: 'Forms',
    value: 2,
    component: Forms
  },
  {
    label: 'Settings',
    value: 3,
    component: Settings
  },
]