import * as S from './SeenMessageIcons.style';
import { memo } from 'react';
import { SeenIcon } from 'icons';

export const SeenMessageIcons = memo(({ seen })=> {
    return(
        <S.ReadUnreadIcon>
            <SeenIcon size={16} color={seen? 'blue' : 'grey'}/>
        </S.ReadUnreadIcon>
    )
},(prevProps, nextProps)=> {
    return prevProps.seen === nextProps.seen;
  }
);