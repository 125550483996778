import styled from 'styled-components/macro'


export const ShortcutsFormContainer = styled.div`
  max-width: calc(100% - 10px);
  padding: var(--seventh-paddind);
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ShortcutsFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .MuiButtonBase-root {
    min-width: 0px !important;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 20px;
  justify-content: flex-end;
`

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 250px;
`