import * as S from './ContainerChat.styles';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS_CHAT_VALUES } from 'configs/constants';
import { debounce } from 'utils/functions';
import { messagesSort } from '../../ChatDataList/ChatDataList.config';
import { PastChatsHistory, CurrentChat, ChatMenu, ChatHeader, UserInfo } from 'components';
import { getVisitorChats } from 'redux_toolkit/reducers/partner/visitors/visitorsSlice';
import { hideModal } from 'redux_toolkit/reducers/modals/modalsSlice';


export const ContainerClientChat = memo(({
  canEdit,
  chatData,
  chats
}) => {
  const dispatch = useDispatch()
  const {currentPartner} = useSelector(({ currentPartner }) => currentPartner);
  const {visitorPastChats, list: visitorsList} = useSelector(({ visitors }) => visitors);

  const [currentTab, setCurrentTab] = useState(0);
  const debaunceCallback = debounce(hideModal, 100);

  const getPastChats = useCallback((id)=> visitorPastChats.get(id), [visitorPastChats]);
  const getCurrentVisitor = useCallback((id) => visitorsList.find(v => v._id === id), [visitorsList]);

  const currentVisitor = useMemo(() => {
    return getCurrentVisitor(chatData.companion._id);
  }, [chatData.companion._id, getCurrentVisitor]);

  const pastChats = useMemo(() => getPastChats(currentVisitor?._id), [currentVisitor?._id, getPastChats]);
  const pastChatsLength = pastChats?.length;

  const getChats = useCallback(() => {
    if (currentVisitor && !pastChatsLength) {
      dispatch(getVisitorChats({partnerId: currentPartner._id, visitorId: currentVisitor._id}));
    }
  }, [currentVisitor, currentPartner._id, pastChatsLength, dispatch]);

  useEffect(() => {
    getChats();
  }, [getChats]);

  if (!chatData) return null; 
  if(!chats.get(chatData.chatId) && canEdit) {
    debaunceCallback()
  }

  return (
    <>
      <ChatHeader canEdit={canEdit} chatData={chatData} />
      <S.ModalContentContainer>
        <S.ClientChatContainer>
          <S.TabsContainer>
            <ChatMenu setCurrentTab={setCurrentTab} currentTab={currentTab} pastChatsLength={pastChatsLength} />
          </S.TabsContainer>
          {currentTab === 0 &&
            <S.ClientChatWrapper>
              <CurrentChat canEdit={canEdit} chatData={chatData} partnerFile={currentPartner.file} />
            </S.ClientChatWrapper>
          }
          {currentTab === 1 &&
            <PastChatsHistory
              visitor={currentVisitor}
              pastChats={pastChats}
            />
          }
        </S.ClientChatContainer>
        {currentTab === 0 && chatData.messages.length &&
          <UserInfo
            isClientChat={true}
            chatInfo={{
              visitorId: chatData.companion._id,
              recipentId: chatData.companion._id,
              startDate: chatData.messages[4],
              transfer: chatData.status === STATUS_CHAT_VALUES.TRANSFER,
              chatHistory: messagesSort(chatData.messages),
              chatId: chatData._id,
              permissionForFile: chatData.file,
              timeOnSite: chatData.timeOnSite
            }}
            canEdit={canEdit}
          />
        }
      </S.ModalContentContainer>
    </>
  )
}, (prevProps, nextProps)=> {
  return (
    prevProps.canEdit === nextProps.canEdit &&
    prevProps.chatData === nextProps.chatData &&
    prevProps.chats === nextProps.chats
  );
})