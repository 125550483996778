import styled from 'styled-components/macro'
import { makeStyles } from '@material-ui/core'

export const TableContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;
  @media (max-width: 600px) {
    flex-direction: column;
    font-size: var(--second-font-size);
  }
`;

export const useStyles = makeStyles(() => ({
  noJoined: {
    background: "#fff9d1",
  },
  tbody: {
    maxHeight: 'calc(100vh - 284px)',
    overflow: 'auto',
    display: 'block',
    '& tr': {
      display: 'table',
      tableLayout: 'fixed',
      width: '100%'
    }
  },
  thead: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%'
  }
}));