import styled from "styled-components/macro";

export const EditFormContainer = styled.div`
  position: absolute;
  width: 40%;
  z-index: 1;
  background: white;
  height: fit-content;
  border-radius: 5px;
  left: 33%;
  top: 10%;
  padding: 25px;

  .closeBtn {
    background: rgb(239, 239, 239);
    color: rgb(29, 60, 84);
  }
`;

export const EditFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 50px;
`;
export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  height: 30%;
  align-items: center;

  button {
    padding: 15px !important;
  }
`;

export const TitleEdit = styled.h2`
  margin: 0px 0px 40px 0px;
  font-size: 20px;
`;
