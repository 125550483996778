import { io } from 'socket.io-client';
import { store } from 'redux_toolkit/store';
import {
  DISABLE_ENABLE_BUTTON,
  STATUS_CHAT_VALUES,
  GET_AGENTS_ROLE,
  STATUS,
  SOCKET_ROLES
} from 'configs/constants';
import {
  setNewChat,
  setNewMessage,
  endChatState,
  incomingChats,
  deleteChatRequest,
  addHistory,
  removeChat,
  addWatchingChat,
  deleteChat,
  updateWatchingChat
} from "redux_toolkit/reducers/chat/chatSlice";
import {
  addBannedVisitor,
  removeBannedVisitor,
  addPartnersVisitors,
  updatePartnerVisitor,
  addVisitorPastChat,
  addVisitorToList,
  editVisitor
} from 'redux_toolkit/reducers/partner/visitors/visitorsSlice';
import { addNewAgent, updateAgentLocal } from 'redux_toolkit/reducers/partner/agents/agentsSlice';
import { connected } from 'redux_toolkit/reducers/connect/connectSlice';
import { hideModalChatId } from 'redux_toolkit/reducers/modals/modalsSlice';
import { updateChat } from 'redux_toolkit/reducers/chat/chatSlice';
import { removeTransferRequest, addTransferRequest } from 'redux_toolkit/reducers/current_partner/currentPartnerSlice';
import { throwOutAgent } from 'redux_toolkit/reducers/app/appSlice';


const dispatch = store.dispatch;
const url = process.env.REACT_APP_SOCKET_URL;
let socket = {};

export const connectSocket = (partnerId, userId, role, name) => {
  socket = io(`${url}/${partnerId}`, {
    auth: {
      name,
      role: SOCKET_ROLES[role],
      userId
    }
  });
}

export const getAllChats = (dispatch) => {
  dispatch(connected(true));
  console.log("Try Connected SOCKET!");

  socket.on('disconnect',() => {
    console.warn("Disconnect SOCKET!");
    dispatch(addPartnersVisitors({
      active: [],
      assigned: [],
      idle: [],
      incoming: [],
      visitors: [],
    }));
  });

  socket.on('user.update', ([agent, agents, partnerFile]) => {
    for(let item of agents){
      if(GET_AGENTS_ROLE[item.role]) {
        item.role = GET_AGENTS_ROLE[item.role];
        dispatch(addNewAgent(item))
      };
    }

    if (agent.transfers) {
      for(let item of agent.transfers) {
        dispatch(addTransferRequest(item));
      }
    }

    if (agent.views) {
      for(let viewChat of agent.views) {
        dispatch(addWatchingChat({chat: viewChat, messages: []}));
      }
    }
    if(agent.requests && agent.requests.length) dispatch(incomingChats(agent.requests));

    dispatch(addPartnersVisitors(agent.visitors));
  });

  socket.on('permission.ban.set', function([date, userPartnerId, banReason]){
    date ? dispatch(addBannedVisitor({_id: userPartnerId, banExpire: date, banReason})) : dispatch(removeBannedVisitor(userPartnerId));
  });

  socket.on('agents.update.status', ([userId, status, name]) => {
    let sendedData = {};
    if(name) sendedData.name = name;
    if(Number.isInteger(status)) sendedData.status = status;
    dispatch(updateAgentLocal({_id: userId, data: sendedData}));

    if(STATUS.OFFLINE === status) dispatch(throwOutAgent(userId));
  });

  socket.on('chats.manual.set', chatId => {
    dispatch(incomingChats([chatId]));
  });

  socket.on('chats.manual.delete', (chatId) => {
    dispatch(deleteChatRequest({chatId}));
  });

  socket.on('chats.recover', (args) => {
    const [chat, companionName, messages, visitorUnread, unreadCount, companionCreated] = args;
    console.warn("chats.recover SocketON chat ----> ", chat);
    console.warn("chats.recover SocketON companionName----> ", companionName);
    console.warn("chats.recover SocketON messages---> ", messages);
    console.warn("chats.recover SocketON visitorUnread ----> ", visitorUnread);
    console.warn("chats.recover SocketON unreadCount ----> ", unreadCount);
    console.warn("chats.recover SocketON companionCreated ----> ", companionCreated); 

    dispatch(setNewChat({chat, messages, unreadCount}));
    if(chat.status === STATUS_CHAT_VALUES.SERVED) {
      getVisitor(chat.companion._id);
    }
    if((companionCreated)) {
      dispatch(updateChat({chatId: chat._id, data: {timeOnSite: companionCreated}}))
    };
  });

  socket.on('chats.end', (chatId) => {
    dispatch(endChatState({chatId}));
  });

  socket.on('chats.delete', (chatId) => {
    dispatch(removeChat({chatId}));
    dispatch(hideModalChatId({chatId}))
  });

  socket.on('chats.status', ([name, userId, chatId, companionCreated]) => {
    if(!isNaN(companionCreated)) dispatch(updateChat({chatId, data:{timeOnSite: companionCreated}}));
  });

  socket.on('message.new.log', ([chatId, message, userId, name]) => {
    dispatch(setNewMessage({chatId, message}));
  });

  socket.on('message.page', ([messages, ended, chatId]) => {
    dispatch(addHistory({chatId, messages}));
  });

  socket.on('message.new', ([message, hasId, chatId]) => {
    console.warn("message.new SocketON ----------> ", message, hasId, chatId);
    dispatch(setNewMessage({chatId, message}));
  });

  socket.on('message.delivered', ([userId, chatId, delivered])=> {
    console.warn("userId, chatId, delivered ======> ", userId, chatId, delivered);

  })


  socket.on('permission.file', ([status, chatId]) => {
    const file = status === true ? DISABLE_ENABLE_BUTTON.ENABLE:DISABLE_ENABLE_BUTTON.DISABLE;
    dispatch(updateChat({chatId, data: {file}}));
  });

  socket.on('agents.new', (agent) => {
    dispatch(addNewAgent(agent));
  });

  // TEAM LEAD
  socket.on('transfer.new.set', (chat)=> {
    dispatch(addTransferRequest(chat));
  });

  socket.on('transfer.new.delete', (chatId)=> {
    dispatch(removeTransferRequest({chatId}));
    dispatch(updateChat({chatId, data: {status: STATUS_CHAT_VALUES.SERVED}}));
  });

  socket.on('visitors.update', (visitor) => {
    dispatch(updatePartnerVisitor(visitor));
  });

  socket.on('chats.view', function([chat, companionName, messages, visitorUnread, unreadCount, visitorCreated]){
    console.warn("'chats.view' SoketON ---chat, companionName, messages, visitorUnread, unreadCount, visitorCreated ----> ", chat, companionName, messages, visitorUnread, unreadCount, visitorCreated);

    dispatch(addWatchingChat({chat, companionName, messages, visitorUnread, unreadCount, visitorCreated}));
  });

  socket.on('chats.views.list.update', function([chatId, oldUserId, user]){
    dispatch(updateWatchingChat({chatId, user}));
  });

  // chi ogtagorcvum
  socket.on('message.read', ([userId, chatId]) => {
    console.warn("message.read userId, chatId --SocketON-- bayc es hle vor chi ogtagorcvel-->", userId, chatId);

    // dispatch(updateUnredMessages({chatId, unreadCount: 0, chatOpen: false}));
  });

  socket.on('update.visitors', ([visitorId, body]) => {
    dispatch(editVisitor({visitorId, body}))
  });

  socket.on('get.visitors', (data) => {
    dispatch(addVisitorToList(data));
  });

  socket.on('transfer.cancel', (chatId) => {
    dispatch(updateChat({chatId, data: {status: STATUS_CHAT_VALUES.SERVED}}));
  });
}

export const getChat = (newChatId)=> {
  socket.emit('chats.get', newChatId);
}

export const messageSend = (chatId, message, file, localUserId)=> {
  console.error("messageSend Emit exav chatId, message, file ----> ", chatId, message, file);

  let pictOrVideo = ['', ''];
  if(file) pictOrVideo = [file.type, file];
  const hashId = Math.random().toString(32).slice(2);
  
  socket.emit('message.set', [chatId, [hashId, [message, pictOrVideo]]]);
  const messageCurcas = ['99999', localUserId, message, "", new Date(), 0];
  console.log("messageCurcas -=-=----> ", messageCurcas)
  // dispatch(setNewMessage({chatId, message: messageCurcas}));
}

export const endThisChat = ({chatId, companionUserId, agent, createdAt})=> {
  socket.emit('chats.end', [chatId, companionUserId]);
  dispatch(deleteChat({chatId}));
  dispatch(addVisitorPastChat({id: companionUserId, agent, createdAt}));
}

export const openChatPage = (chatId)=> {
  console.error("-openChatPage");
  dispatch(updateChat({chatId, data: {unreadCount: 0, chatOpen: true}}));
  readMessage(chatId);
}

export const readMessage = (chatId)=> {
  console.error("message.read SocketEmit chatID ------>", chatId)
  socket.emit('message.read', chatId);
}

export const filePermission = (chatId)=> {
  socket.emit('permission.file', chatId);
}

export const internalChat = (receiverId)=> {
  socket.emit('chats.internal.set', receiverId);
}

export const scrollMessages = (chatId, start, cb)=> {
  socket.emit('message.page', [chatId, `i${start}`]);
}

export const visitorBan = (visitorId, exp, banReason)=> {
  socket.emit('permission.ban', [visitorId, exp, banReason]);
  !exp && dispatch(removeBannedVisitor(visitorId));
}

export const transferChat = (chatId, rapid=false)=> {
  socket.emit('chats.transfer.set', [chatId, rapid]);
  dispatch(updateChat({chatId, data: {status: STATUS_CHAT_VALUES.TRANSFER}}));
}

export const transferAssign = (chatId, assignedId, rapid)=> {
  transferChat(chatId, rapid);

  socket.emit('chats.transfer.offer', [chatId, assignedId]);
}

export const viewChat = (chatId)=> {
  socket.emit('chats.view', chatId);
}

export const viewChatEnd = (chatId)=> {
  socket.emit('chats.view.end', chatId);
}

export const agentStatusUpdate = (status)=> {
  socket.emit('agents.update.status', status);
}

export const updatePartnerRequest = (status)=> {
  socket.emit('admins.set.request', +status);
}

export const logoutSocket = ()=> {
  socket.emit('logout');
}

export const logoutAgent = (agentId)=> {
  socket.emit('agents.logout', agentId);
}

export const updateActivity = (_id, status)=> {
  socket.emit('agents.inactive', [_id, status]);
  dispatch(updateAgentLocal({_id, data: {status}}));
}

export const updateVisitorData = (userPartnerId, data)=> {
  socket.emit('update.visitors', [userPartnerId, data]);
}

export const getVisitor = (userPartnerId)=> {
  socket.emit('get.visitors', [userPartnerId]);
}