import styled from 'styled-components/macro'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


export const StatusSelect = styled(Select)`
  margin-left: 10px;
  border-radius: 20px;
  .MuiSelect-root {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`

export const SelectItem = styled(MenuItem)`
  color: ${({ color }) => color};
  font-size: var(--second-font-size);
  @media (max-width:1024px){
    font-size: 8px;
  }
  &&.small_item{
    font-size: 9px;
  }
`

export const TypeIcon = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ color }) => color};
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const styles = {
  valueContainerStyles: {
    display: 'flex' 
  },
  optionStyles: {
    padding: '10px 8px 10px !important',
    cursor: 'pointer',
  },
  controlStyles: {
    cursor: 'pointer'
  },
  singleValueStyles: (props) => ({
    cursor: 'pointer',
    margin: '0px',
    width: 10,
    height: 10,
    borderRadius: '50%',
    background:  props.data.color,
    position: 'absolute',
    top: 0,
    right: 0
  })
}