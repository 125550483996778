export const PROJECT_VERSION = '1.5';

export const USER_ACCESSIBILITY_TYPES = {
  ONLINE: {
    value: 4,
    label: 'ONLINE',
    color: 'rgb(0, 148, 54)'
  },
  AWAY: {
    value: 3,
    label: 'AWAY',
    color: 'rgb(213, 99, 0)'
  },
  INVISIBLE: {
    value: 2,
    label: 'INVISIBLE',
    color: 'rgb(128, 128, 128)'
  }
}

export const STATUSES_LIST = [
  {
    id: 0,
    value: 4,
    label: 'online',
    color: 'rgb(0, 148, 54)'
  },
  {
    id: 1,
    value: 3,
    label: 'away',
    color: 'rgb(213, 99, 0)'
  },
  {
    id: 2,
    value: 2,
    label: 'invisible',
    color: 'rgb(128, 128, 128)'
  }
]

export const VISITOR_STATUSES = {
  IDLE: 'VISITOR_STATUSES.IDLE',
  ACTIVE: 'VISITOR_STATUSES.ACTIVE',
  SERVED: 'VISITOR_STATUSES.SERVED',
  INCOMING: 'VISITOR_STATUSES.INCOMING',
  ASSIGNED: 'VISITOR_STATUSES.ASSIGNED',
}

export const SUPER_ADMIN_URL = 'superadmin';
export const ROLES = {
  SUPERADMIN: 0,
  ADMIN: 1,
  TEAMLEADER: 2,
  AGENT: 3,
  VISITOR: 4
}

export const USERS_ROLES = {
  '2': 'Team Lead',
  '3': 'Agent',
}

export const USER_STATUSES = {
  '0': 'Inactive',
  '1': 'Offline',
  '2': 'Invisible',
  '3': 'Away',
  '4': 'Online'
}

export const STATUS = {
  INACTIVE: 0,
  OFFLINE: 1,
  INVISIBLE: 2,
  AWAY: 3,
  ONLINE: 4
}

export const ALL_ROLES = Object.values(ROLES)

export const languagesSelect = {
  'en': 'English',
  'fa': 'Persian',
  'ar': 'Arabic',
  'ku': 'Kurdish',
  'fr': 'French',
  'ru': 'Russian',
  'tr': 'Turkish'
}
export const LANGUAGES = [
  {
    id: 0,
    label: 'English',
    value: 'en'
  },
  {
    id: 1,
    label: 'Persian',
    value: 'fa'
  },
  {
    id: 2,
    label: 'Arabic',
    value: 'ar'
  },
  {
    id: 3,
    label: 'Kurdish',
    value: 'ku'
  },
  {
    id: 4,
    label: 'French',
    value: 'fr'
  },
  {
    id: 5,
    label: 'Russian',
    value: 'ru'
  },
  {
    id: 6,
    label: 'German',
    value: 'de'
  },
  {
    id: 7,
    label: 'Turkish',
    value: 'tr'
  }
]

export const CHAT_WINDOW_SIZES = {
  CIRCULAR: 1,
  BAR: 2,
}

export const CHAT_WIDGET_POSITIONS = {
  BOTTOM_LEFT: 1,
  BOTTOM_RIGHT: 2
}

export const CHAT_MOBILE_WIDGETS = {
  OVERLAY_WINDOW: 1,
  CHAT_POPOUT: 2
}

export const FILE_PARTNERS_TYPE = {
  DISABLE: 0,
  MANUAL_DISABLE: 1,
  ENABLE: 2,
  MANUAL_ENABLE: 3,
}
export const FILE_STORAGE_PERMISSION = [
  {id: 0, label: "disable"}, {id: 2, label: "enable"}
];

export const FILE_PERMISSION_CHAT = [null, "enable", "disable"];
export const DISABLE_ENABLE_BUTTON = {
  DISABLE: 1,
  ENABLE: 2
};

export const IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'raw', 'webp' ];
export const VIDEO_TYPES = ['mp4', 'mp3'];

export const MESSAGES_ITEM_COUNT = 5;


export const STATUS_CHAT = [
  'auto',
  'manual',
  'served',
  'transfer',
  'internal', 
  'end',
  'missed'
];

export const STATUS_CHAT_VALUES = {
  AUTO: 0,
  MANUAL: 1,
  SERVED: 2,
  TRANSFER: 3,
  INTERNAL: 4,
  END: 5,
  MISSED: 6
}

export const GET_AGENTS_ROLE = {
  'team-leads': 2,
  'agents': 3
}

export const SOCKET_ROLES = {
  '1': 'admins',
  '2': 'team-leads',
  '3': 'agents'
};

export const ROLES_PAGES = {
  '0': ['home', 'partners', 'admins'],
  '1': ['home', 'history', 'personal', 'agents', 'banned', 'shortcuts', 'visitors', 'widget'],
  '2': ['home', 'history', 'personal', 'agents', 'banned', 'shortcuts', 'visitors'],
  '3': ['home', 'history', 'personal']
}

export const BROWSERS_LIST = ['chrome', 'firefox', 'safari', 'opera', 'edge'];
export const PLATFORM_LIST = ['windows', 'ubuntu', 'linux', 'android', 'ios', 'mac'];

export const MESSAGE_STATUS = {
  0: 'undelivered',
  1: 'delivered'
}