import styled from 'styled-components/macro'


import FormHeaderBackground from 'images/NotAuthFormBackground.png'
import FormHeaderBottomBackground from 'images/NotAuthFormBottomBackground.png'


export const NotAuthFormLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(410px + (487 - 410) * (100vw - 1024px) / (1920 - 1024));
  background:white;
  position:relative;
`

export const FormHeader = styled.div`
  display: flex;
  background: url(${FormHeaderBackground});
  background-size: cover;
  height: ${({ height }) => height}px;
  position: relative;
  padding: var(--padding-primary);
`

export const HeaderBottomBackground = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: url(${FormHeaderBottomBackground});
  background-size: cover;
  left: 0px;
`

export const FormBody = styled.div`
  background: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding:  30px 0px ;
  align-items: center;
  position: relative;
  margin: auto;
  width: calc(410px + (487 - 410) * (100vw - 1024px) / (1920 - 1024));
  text-align: center;
  max-width: 300px;
`

export const Logo = styled.img`
  width: 209px;
  height: 71px;
`