import styled from 'styled-components'


export const ConfirmationAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`


export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`