import styled from 'styled-components';
import MaterialTabs from '@material-ui/core/Tabs';
import MaterialTab from '@material-ui/core/Tab';
import MaterialPaper from '@material-ui/core/Paper';

export const Tabs = styled(MaterialTabs)`
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)
  background: #f5f5f5;

`;

export const Paper = styled(MaterialPaper)`
  background: #f5f5f5;

  @media (max-width:950px){
    & * {
      font-size: 13px;
    }
  }
`;

export const Tab = styled(MaterialTab)`
  font-size: var(--fourth-font-size);
  font-weight: var(--wider-font-weight);
  color: rgba(0, 0, 0, 0.54);

  &:hover {
    color: var(--primary-dark-blue);
  }
  &:active {
    color: var(--primary-dark-blue);
  }
  &:selected {
    color: var(--primary-dark-blue);
  }
`;