import styled from 'styled-components/macro'


export const BannedPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const TableContainer = styled.div`
  margin-top: 20px;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .SearchInput {
    width: 30%;
    max-width:300px;
    min-width:10%;
  }
`;