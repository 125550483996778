import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'utils/functions';
import {
  readMessage
} from 'socket/socket';
import { ContainerClientChat } from './ContainerChat/ContainerChat';
import { hideModalChatId } from 'redux_toolkit/reducers/modals/modalsSlice';


export const ClientChat = ({
  canEdit = true,
  chatId,
  hideModal
}) => {
  const dispatch = useDispatch();

  const { chats, watchingChats } = useSelector(({ chat })=> chat);
  // console.log("chats, watchingChats ---reduxData----> ", chats, watchingChats );
  
  const [chatData, setChatData] = useState();
  const debaunceCallback = debounce(hideModal, 100);

  const readAllMessages = useCallback((chatId)=> {
    return readMessage(chatId);
  }, []);

  useEffect(()=>{
    const currentChatData = canEdit? chats.get(chatId) : watchingChats.get(chatId);
    if(!currentChatData) dispatch(hideModalChatId({chatId}));
    setChatData(currentChatData);
    if(chatData?.chatOpen === true && !currentChatData?.chatEnd) readAllMessages(chatId);
  }, [
    chats,
    watchingChats,
    canEdit,
    chatData?.chatOpen,
    chatId,
    readAllMessages,
    dispatch
  ]);

  if(!chats.get(chatData?.chatId) && canEdit) {
    debaunceCallback()
  }

  return (
    <div>
      {chatData && <ContainerClientChat chatData={chatData} canEdit={canEdit} chats={chats} />}
    </div>
  )
}
