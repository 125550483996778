import styled from 'styled-components'
import MaterialButton from '@material-ui/core/Button'

export const PartnersPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`

export const ActionsContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
`

export const ActionButton = styled(MaterialButton)`
  min-width: 0px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`
export const HeaderContainerActions = styled.div`
  color:#fff;
  .MuiSwitch-colorPrimary.Mui-checked{
    color: #444!important;
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{
    background-color:#999!important;
    opacity: 1!important;
  }
  
`
export const Plus = styled(MaterialButton)`
  color: #fff;
  .fa-plus{
    font-size: 22px!important;
  }
`


