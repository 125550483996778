import styled from "styled-components/macro";

export const TranscriptContainer = styled.div`
  width: 100%;
  padding: var(--padding-primary);
  overflow: scroll;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  max-height: 465px;
`;

export const ChatInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0;
  border-bottom: 1px dashed #e0e0e0;
  width:100%;
`;

export const InfoText = styled.p`
  color: #555;
  text-align: start;
  font-size: var(--fourth-font-size);
  margin: 0 4px;
  padding: 0;
`;

export const ChatStartedDate = styled.div`
  color: #757575;
  font-size: var(--fourth-font-size);
  text-align: center;
  margin-top: 5px;
`;

export const ChatMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  color:black;
`;

export const MessageItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: var(--padding-secondary);
  border-top: 1px dashed #e0e0e0;
  color: #555;
  position: relative;
  padding: var(--padding-secondary);
  &.botMessage {
    text-align: center;
  }
`;
export const MessageSender = styled.p`
  font-style: italic;
  font-weight: var(--wider-font-weight);
  margin: 0 4px;
  padding: 0;
  font-size: var(--third-font-size);
`;
export const MessageDate = styled.span`
  color: #757575 !important;
  right: -4px;
  bottom: -11px;
  position: absolute;
  font-style: italic;
  margin: 0 4px;
  padding: 0;
  font-size: var(--second-font-size);
  margin-bottom: 2px;
`;

export const Message = styled.div`
  color: #3c4858;
  margin: 0 4px;
  word-wrap: break-word;
  font-size: var(--third-font-size);
  padding-bottom: 10px;
  &.botMessage {
    color: #9e9e9e;
  }
`;
export const MessageUser = styled.div`
  position: relative;
  color: #9e9e9e;
  font-size: var(--third-font-size);
  &.agentMessage {
    text-align: left;
    color: #3c4858;
  }
  &.visitorMessage {
    color: #ffa726;
    text-align: left;
  }
`;

export const MessageTime = styled.div`
  color: #757575;
  font-style: italic;
  font-size: var(--third-font-size);
  align-self: flex-end;
`;


export const MessageImage=styled.img`
 width:50px;
 height:50px;
 `