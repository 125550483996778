import styled from 'styled-components/macro';

export const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px 16px;
  background: var(--primary-dark-blue);
  align-items: center;
  position: fixed;
  width: calc(100% - 64px);
  max-width: 1200px;
  z-index: 9;
  border-top-left-radius: 3.5px;
  border-top-right-radius: 3.5px;

  div {
    display: flex;
  }

  .MuiButtonBase-root {
    display: flex;
    color: #fff;
    border-radius: 50%;
    min-width: unset;

    .MuiSvgIcon-root {
      color: #fff;
    }

    &:first-child {
      align-items: flex-end;
    }
  }
`

export const ClientName = styled.div`
  color: #fff;
  font-weight: var(--wider-font-weight);
  font-size: var(--fourth-font-size);  
`

export const ContainerBtn = styled.div`
  display: flex;
  justify-content: end;
`