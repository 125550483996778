import * as S from "./DevPortal.style";
import { useStyles } from "./DevPortal.style";
import React from "react"
import { Backdrop, IconButton, Link, Modal } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"


export function DeveloperPortal({
  show,
  onShow,
  onHide,
  color,
  sidebarClosed
}) {
  const classes = useStyles();

  return (
    <>
      <Link
        component="button"
        variant="body2"
        style={{ color: color, textDecoration: "underline" }}
        onClick={onShow}
      >
        {
          !sidebarClosed
            ? 'Development Portal'
            : <FontAwesomeIcon icon={faInfoCircle} />
        }
      </Link>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={show}
        onClose={onHide}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="developer-portal">
          <IconButton className="close-btn" onClick={()=> onHide()}><FontAwesomeIcon icon={faInfoCircle} /></IconButton>
          <S.Iframe
            title="Developer portal"
            src="https://zeyinfo.site/"
            width="590"
          ></S.Iframe>
        </div>
      </Modal>
    </>
  );
}